<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">        
        <strong>emw</strong>
      </div>

    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
    <!-- <BottomNav/> -->
    <FooterImprint/>

  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import FooterImprint from './components/FooterImprint';
// import BottomNav from './components/BottomNav';

export default {
  name: 'App',

  components: {
    HelloWorld,
    FooterImprint,
    // BottomNav
  },

  data: () => ({
    //
  }),
};
</script>
