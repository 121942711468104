<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-card
          class="mx-auto"
          max-width="580"
          outlined
        >
        <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          communication and change
        </div>
        <v-list-item-title class="headline mb-1">
          Dr. Eric Marzo<span>&#8209;</span>Wilhelm
        </v-list-item-title>
        <v-list-item-subtitle>Freelance Consulting &amp; Interim Management</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="120"
        color="grey"
      >
        <v-img src="../assets/avatar.jpg"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        href="https://www.linkedin.com/in/eric-marzo-wilhelm/"
        target="_blank"
        outlined
        rounded        
      >
        <v-icon>mdi-linkedin</v-icon><br>
        <span>linkedin</span>
      </v-btn>
      <v-btn
        href="https://www.xing.com/profile/Eric_MarzoWilhelm/cv"
        target="_blank"
        outlined
        rounded        
      >
        <v-icon>mdi-xing</v-icon><br>
        <span>xing</span>
      </v-btn>
    </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
